import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Final from './FinalDocument/Final';
import Footer from './Footer/Footer';
import Main from './Main/Main';
import Navbar from './Navbar/Navbar';
import Standarts from './Standarts/Standarts';
import StandartsLast from './StandartsLast/StandartsLast';
import StandartNext from './StandartsNext/StandartNext';
import { fetchAllDocs, fetchCarousel } from '../actions'
import { connect } from 'react-redux'
import NavbarMobile from './Navbar/NavbarMobile';



function App(props) {
    useEffect(() => {
        props.fetchAllDocs()
        props.fetchCarousel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Router>
            <ScrollToTop />
            <Navbar />
            <NavbarMobile />
            <Switch>
                <Route exact path='/'>
                    <Main />
                </Route>
                <Route exact path='/list/:id'>
                    <Standarts />
                </Route>
                <Route exact path='/list/next/:id'>
                    <StandartNext />
                </Route>
                <Route exact path='/list/last/:id'>
                    <StandartsLast />
                </Route>
                <Route exact path='/info'>
                    <Final />
                </Route>
            </Switch>
            <Footer />
        </Router>
    )
}




function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}



export default connect(null, { fetchAllDocs, fetchCarousel })(App)