import { combineReducers } from 'redux'

const fetchAllDocsReducer = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_ALL_DOCS':
            return action.payload
        default:
            return state
    }
}
const fetchCarouselReducer = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_CAROUSEL':
            return action.payload
        default:
            return state
    }
}

const fetchDocsInformReducer = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_DOCS_DETAILED_COUNT':
            return action.payload
        default:
            return state
    }
}
const fetchDocsInformNextReducer = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_DOCS_DETAILED_COUNT_NEXT':
            return action.payload
        default:
            return state
    }
}
const fetchDocsInformNextLastReducer = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_DOCS_DETAILED_COUNT_NEXT_LAST':
            return action.payload
        default:
            return state
    }
}
const fetchFinalInfo = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_FINAL_INFO':
            return action.payload
        default:
            return state
    }
}
const changeLanguageReducer = (state = [], action) => {
    switch (action.type) {
        case 'CHANGE_LANGUAGE':
            return action.payload
        default:
            return state
    }
}

// FETCH_DOCS_DETAILED_COUNT_NEXT_LAST
export default combineReducers({
    allDocs: fetchAllDocsReducer,
    detailed_count: fetchDocsInformReducer,
    detailed_count_next: fetchDocsInformNextReducer,
    detailed_count_next_last: fetchDocsInformNextLastReducer,
    selectedDoc: fetchFinalInfo,
    language: changeLanguageReducer,
    carousel: fetchCarouselReducer
})