import React from 'react';
import img from '../../../assets/images/main.png'
import style from './Main1.module.css'
import { useTranslation } from 'react-i18next'

function Main1() {
    const { t } = useTranslation();
    return (
        <div className={style.main} style={{ backgroundImage: `url(${img})` }}>
            <h1>{t("main.h1")}</h1>
        </div>
    )
}

export default Main1
