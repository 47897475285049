import React, { Fragment } from 'react'
import Main1 from './MainParts/Main1'
import Main2 from './MainParts/Main2'
import Main3 from './MainParts/Main3'
import Main4 from './MainParts/Main4'

function Main() {
    return (
        <Fragment>
            <Main1 />
            <Main2 />
            <Main3 />
            <Main4 />
        </Fragment>
    )
}

export default Main
