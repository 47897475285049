import React from 'react'
import style from './MainParts.module.css'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function MainParts(props) {
    const { i18n, t } = useTranslation();

    return (
        <div className={style.main}>
            <h1>{t('mainp.h1')}</h1>
            <div className={style.box}>
                <h3>{t('mainp.status')}</h3>
                <h4 className={style.one}>{props.selectedDoc?.status <= 10 && t('mainp.one')}</h4>
                <h4 className={style.two}>{props.selectedDoc?.status > 15 && t('mainp.two')}</h4>
                <h4 className={style.three}>{props.selectedDoc?.status > 10 && props.selectedDoc?.status <= 15 && t('mainp.three')}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.code')}</h3>
                <Link to='/'>{props.detail[1]?.code} - {
                    i18n.language === 'ru' ? props.detail[1]?.name_ru : props.detail[1]?.name_uz
                }</Link>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.organ')}</h3>
                <h4>{t('mainp.organh4')}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.date')}</h3>
                <h4>{props.selectedDoc?.comissioning_date?.split('-').reverse().join('.')}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.dateF')}</h3>
                <h4>{props.selectedDoc?.validity ? props.selectedDoc?.validity.split('-').reverse().join('.') : 'Без ограничения'}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.np')}</h3>
                <h4>{props.selectedDoc ? (
                    i18n.language === 'ru' ? props.selectedDoc.number_of_pages_ru : props.selectedDoc.number_of_pages_uz
                ) : 'Без ограничения'}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.doc1')}</h3>
                <h4>{t('mainp.doc1s')}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.doc2')}</h3>
                <h4>{props.selectedDoc?.withdrawn && props.selectedDoc.withdrawn[0].name}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('mainp.doc3')}</h3>
                <h4>{props.selectedDoc?.replacing ? props.selectedDoc.replacing[0].name : 'Введён впервые'}</h4>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        detail: state.detailed_count_next_last,
        selectedDoc: state.selectedDoc
    }
}


export default connect(mapStateToProps)(MainParts)
