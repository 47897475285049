import React from 'react'
import style from './MainParts.module.css'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'


function Links() {
    const { t } = useTranslation();
    return (
        <div className={style.main}>
            <h1>{t('links.h1')}</h1>

            <div className={style.box}>
                <h3>{t('links.code')}</h3>
                <h4>{t('links.no')}</h4>
            </div>
            <div className={style.box}>
                <h3>{t('links.organ')}</h3>
                <h4>{t('links.organt')}</h4>
            </div>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        detail: state.detailed_count_next_last,
        selectedDoc: state.selectedDoc
    }
}


export default connect(mapStateToProps)(Links)
