import React, { useState } from 'react';
import style from './NavbarMobile.module.css';
import logo from '../../assets/images/logo.svg'
import { FaPhoneAlt, FaCaretDown } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'


function NavbarMobile() {
    const [language, setLanguage] = useState('O`zbek')
    const [navClicked, setNavClicked] = useState(false)

    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }
    // {t("footer.year")}
    return (
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.left}>
                    <div className={style.logoContainer}>
                        <div className={style.logo} style={{ backgroundImage: `url(${logo})` }}></div>
                        <h3>{t("navbar.logo")} </h3>
                    </div>
                </div>
                <div className={style.right}>
                    <div className={style.numbers}>
                        <div className={style.svgContainer}>
                            <FaPhoneAlt />
                        </div>
                        <div className={style.phones}>
                            <h5>+998 71 235 9327</h5>
                            <h5>+998 71 237 6504</h5>
                        </div>
                    </div>
                    <div className={style.langSearch}>
                        <div className={style.language}>
                            <h3>{language}</h3>
                            <div className={style.down}>
                                <FaCaretDown />
                            </div>
                            <div className={style.langs}>
                                {language !== 'O`zbek' && <h3 onClick={() => {
                                    setLanguage('O`zbek')
                                    changeLanguage('uz')
                                }}>O`zbek</h3>}
                                {language !== 'Русский' && <h3 onClick={() => {
                                    setLanguage('Русский')
                                    changeLanguage('ru')
                                }}>Русский</h3>}
                            </div>
                        </div>
                        <div className={style.search}>
                            <BsSearch />
                        </div>
                    </div>
                </div>
            </div>
            {
                navClicked ? <AiOutlineClose className={style.menu} onClick={() => setNavClicked(!navClicked)} /> : <AiOutlineMenu className={style.menu} onClick={() => setNavClicked(!navClicked)} />
            }
            {
                navClicked &&
                <div className={style.navOpen}>
                    <NavLink onClick={() => setNavClicked(!navClicked)} to='/' activeStyle={{ borderBottom: ' 2px solid #DA1F28' }}>
                        {t("navbar.link1")}
                    </NavLink>
                    <NavLink onClick={() => setNavClicked(!navClicked)} to='/' activeStyle={{ borderBottom: ' 2px solid #DA1F28' }}>
                        {t("navbar.link2")}
                    </NavLink>
                </div>
            }
        </div>
    )
}

export default NavbarMobile
