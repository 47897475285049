import React, { useState } from 'react'
import style from './Change.module.css'
import { connect } from 'react-redux'
import { BiChevronDown } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'


function Change() {
    const { t } = useTranslation();
    const [clicked, setClicked] = useState(false)

    return (
        <div className={style.main}>
            <h1>{t('change.h1')}</h1>
            <div className={style.tables}>
                <div className={style.table} onClick={() => setClicked(!clicked)}>
                    <h1>{t('change.subh1')}</h1>
                    <BiChevronDown className={!clicked ? style.svg : ''} />

                    <div className={clicked ? style.open : ''}>
                        <h1>{t('change.openh1')}</h1>
                        <h1>{t('change.openh1next')}</h1>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        detail: state.detailed_count_next_last,
        selectedDoc: state.selectedDoc
    }
}


export default connect(mapStateToProps)(Change)
