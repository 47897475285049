import React from 'react'
import style from './Change.module.css'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'


function Change(props) {
    const { i18n } = useTranslation();

    const text = props.selectedDoc?.short_description?.split('.')
    const textuz = props.selectedDoc?.short_description_uz?.split('.')

    return (
        <div className={style.main}>
            <h1>Краткое описание</h1>
            <div className={style.container}>
                {
                    i18n.language === 'ru' ?
                        text?.map(p => {
                            return (
                                <p><span></span>{p}.</p>
                            )
                        })
                        :
                        textuz?.map(p => {
                            return (
                                <p><span></span>{p}.</p>
                            )
                        })
                }
            </div>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        selectedDoc: state.selectedDoc
    }
}


export default connect(mapStateToProps)(Change)
