import React from 'react'
import style from './Main2.module.css'
import { BsSearch } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'


function Main2() {
    const { t } = useTranslation();
    return (
        <div className={style.main}>
            <div className={style.container}>
                <h1>{t("main.search")}</h1>
                <input type="text" />
                <div className={style.buttons}>
                    <button className={style.black}>{t("main.button1")}</button>
                    <button className={style.blue}>{t("main.button2")} <BsSearch /></button>
                </div>
            </div>
        </div>
    )
}

export default Main2
