import React from 'react';
import style from './Final2.module.css'
import { connect } from 'react-redux'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

function Final2(props) {
    const { t } = useTranslation();

    console.log(props.selectedDoc.replacing);
    // if (window.Error) {
    //     window.location.replace('/')
    // }
    return (
        <div className={style.main}>
            <div className={style.container}>
                {props.selectedDoc.withdrawn && props.selectedDoc.withdrawn[0].name && props.selectedDoc.replacing[0].name &&
                    <h1>{t("final2.h1")}</h1>
                }
                <div className={style.boxes}>
                    {props.selectedDoc?.withdrawn !== undefined &&
                        props.selectedDoc.withdrawn[0].name &&
                        <div className={style.box}>
                            <h3><AiOutlineCloseCircle /> {props.selectedDoc.withdrawn[0].name}</h3>
                            <h4>{t("final2.h4")}</h4>
                        </div>
                    }
                    {
                        props.selectedDoc.replacing !== undefined &&
                        <div className={style.box}>
                            <h3><AiOutlineCloseCircle /> {props.selectedDoc.replacing[0].name}</h3>
                            <h4>{t("final2.h4")}</h4>
                        </div>
                    }
                    {
                        (props.selectedDoc.doc_index || props.selectedDoc.doc_registry_number) &&
                        <div className={style.box}>
                            <h3><AiOutlineCloseCircle /> {props.selectedDoc.doc_index} {props.selectedDoc.doc_registry_number}: {props.selectedDoc.approved_year}</h3>
                            <h4>{t("final2.h4")}</h4>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        detail: state.detailed_count_next_last,
        selectedDoc: state.selectedDoc
    }
}

export default connect(mapStateToProps, null)(Final2)
