import React, { useState } from 'react'
import style from './FinalSidebar.module.css'
import { useTranslation } from 'react-i18next'

function FinalSidebar({ fetchSidebarItem }) {
    const { t } = useTranslation();
    const [clicked, setClicked] = useState(1)
    return (
        <div className={style.main}>
            <h1 className={clicked === 1 ? style.clicked : ''} onClick={() => {
                fetchSidebarItem(1)
                setClicked(1)
            }}>{t('finalS.one')}</h1>
            <h1 className={clicked === 2 ? style.clicked : ''} onClick={() => {
                fetchSidebarItem(2)
                setClicked(2)
            }}>{t('finalS.two')}</h1>
            <h1 className={clicked === 3 ? style.clicked : ''} onClick={() => {
                fetchSidebarItem(3)
                setClicked(3)
            }}>{t('finalS.three')} </h1>
            <h1 className={clicked === 4 ? style.clicked : ''} onClick={() => {
                fetchSidebarItem(4)
                setClicked(4)
            }}>{t('finalS.four')}</h1>
            <h1 className={clicked === 5 ? style.clicked : ''} onClick={() => {
                fetchSidebarItem(5)
                setClicked(5)
            }}> {t('finalS.five')}</h1>
        </div>
    )
}

export default FinalSidebar
