import React, { useState } from "react";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import style from './StandartsLast2.module.css'
import { Link } from 'react-router-dom'
import { fetchFinalInfo } from '../../../actions'
import { connect } from 'react-redux'
import img from '../../../assets/images/download.svg'
import imgRu from '../../../assets/images/ru.svg'
import imgUz from '../../../assets/images/uz.svg'
import { useTranslation } from 'react-i18next'


function StandartNext1(props) {
    const { i18n, t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1)

    const changeCurrentPage = numPage => {
        setCurrentPage(numPage);
    };

    return (
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.section}>
                    <div className={style.number}>
                        <h3>№</h3>
                    </div>
                    <div className={style.docs}>
                        <h3>{t("slast.h1")}</h3>
                    </div>
                    <div className={style.status}>
                        <h3>{t("slast.status")}</h3>
                    </div>
                    <div className={style.save}>
                        <h3>{t("slast.button")}</h3>
                    </div>
                </div>
                <div className={style.items}>

                    {
                        props.information[0]?.results?.length > 0
                            ?
                            props.information[0]?.results?.map((info, index) => {
                                if (((currentPage - 1) * 10 <= index) && ((currentPage * 10) > index)) {
                                    return (
                                        <Link to={`/info`} className={style.item} key={index} onClick={() => props.fetchFinalInfo(info)}>
                                            <div className={style.number}>
                                                <h3>{index}</h3>
                                            </div>
                                            <div className={style.name}>
                                                <Link to={`/info`}>{info.doc_index} {info.doc_registry_number}:{info.approved_year}</Link>
                                                <h3>{i18n.language === 'ru' ? info.name_ru : info.name_uz}</h3>
                                            </div>
                                            <div className={style.status}>
                                                <h3 className={style.one}>{info.status <= 10 && t("slast.one")}</h3>
                                                <h3 className={style.two}>{info.status > 15 && t("slast.two")}</h3>
                                                <h3 className={style.three}>{info.status > 10 && info.status <= 15 && t("slast.three")}</h3>
                                            </div>
                                            <div className={style.save}>
                                                <div className={style.button}>
                                                    <h4>{t("slast.save")}</h4>
                                                    <img src={img} alt="download" />
                                                    {/* <a href={props.document?.file_pdf_ru} rel="noreferrer" download>{t("final1.ru")}</a>
                            <a href={props.document?.file_pdf_uz} rel="noreferrer" download>{t("final1.uz")}</a> */}
                                                    <div className={style.languages}>
                                                        <a href={info?.file_pdf_ru} rel="noreferrer" download><img src={imgRu} alt="russian flag" /> Русский</a>
                                                        <a href={info?.file_pdf_ru} rel="noreferrer" download><img src={imgUz} alt="uzb flag" />Узбек</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                } else {
                                    return null;
                                }
                            })
                            : <h1 className={style.error}>{t("slast.notfound")}</h1>
                    }
                </div>
                {
                    props.information[0]?.length > 10 && <div className="container" style={{ textAlign: 'center' }}>
                        <div className="box-theme">
                            <div className="demo">
                                <Pagination
                                    currentPage={currentPage}
                                    totalSize={Math.ceil((props.information[0]?.length / 10)) * 10}
                                    changeCurrentPage={changeCurrentPage}
                                    theme="bootstrap"
                                />

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

const mapStatToProps = state => {
    return {
        information: state.detailed_count_next_last
    }
}

export default connect(mapStatToProps, { fetchFinalInfo })(StandartNext1);