import React from 'react';
import { connect } from 'react-redux';
import style from './Final1.module.css'
import img from '../../../assets/images/save.svg'
import { useTranslation } from 'react-i18next'

function Final1(props) {
    const { i18n, t } = useTranslation();
    return (
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.information}>
                    <h1>{t("final1.h1")} ({props.document?.doc_index})</h1>
                    <h1>{props.document?.doc_index}  {props.document?.doc_registry_number}:{props.document?.approved_year}</h1>
                    <h2>
                        {
                            i18n.language === 'ru' ? props.document?.name_ru : props.document?.name_uz
                        }
                    </h2>
                </div>
                <div className={style.save}>
                    <div className={style.button}>
                        <h3>{t("final1.save")}</h3>
                        <img src={img} alt="save" />
                        <div className={style.language}>
                            <a href={props.document?.file_pdf_ru} rel="noreferrer" download>{t("final1.ru")}</a>
                            <a href={props.document?.file_pdf_uz} rel="noreferrer" download>{t("final1.uz")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        document: state.selectedDoc
    }
}
export default connect(mapStateToProps, null)(Final1)
