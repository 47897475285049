import React, { Fragment } from 'react'
import Main2 from '../Main/MainParts/Main2'
import Standarts1 from './StandartsParts/Standarts1'
import Standarts2 from './StandartsParts/Standarts2'

function Standarts() {
    return (
        <Fragment>
            <Main2 />
            <Standarts1 />
            <Standarts2 perPage={10} />
        </Fragment>
    )
}

export default Standarts
