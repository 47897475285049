import React, { Fragment } from 'react'
import Main2 from '../Main/MainParts/Main2'
import StandartsLast1 from './StandartsLastParts/StandartsLast1'
import StandartsLast2 from './StandartsLastParts/StandartsLast2'

function StandartsLast() {
    return (
        <Fragment>
            <Main2 />
            <StandartsLast1 />
            <StandartsLast2 perPage={10} />
        </Fragment>
    )
}

export default StandartsLast
