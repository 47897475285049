import React, { Fragment } from 'react'
import Final1 from './FinalParts/Final1'
import Final2 from './FinalParts/Final2'
import FinalSidebar from './FinalParts/FinalSidebar'
import Links from './FiveParts/Links'
import Guide from './FiveParts/Guide'
import Text from './FiveParts/Text'
import Change from './FiveParts/Change'
import MainParts from './FiveParts/MainParts'
import { useState } from 'react'
import style from './Final.module.css'

function Final() {
    const [number, setNumber] = useState(1)

    const fetchSidebarItem = name => {
        setNumber(name)
    }

    return (
        <Fragment>
            <Final1 />
            <div className={style.main}>
                <FinalSidebar fetchSidebarItem={fetchSidebarItem} />
                {number === 1 && <MainParts />}
                {number === 2 && <Change />}
                {number === 3 && <Text />}
                {number === 4 && <Guide />}
                {number === 5 && <Links />}
            </div>
            <Final2 />
        </Fragment>
    )
}

export default Final
