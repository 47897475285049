import React, { useState } from "react";
import { FaSortAmountDown } from "react-icons/fa";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import style from '../../Standarts/StandartsParts/Standarts2.module.css'
import { Link } from 'react-router-dom'
import { fetchDocsDetailed_count_next_last } from '../../../actions'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'


function StandartNext1(props) {
    const { i18n, t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1)

    const changeCurrentPage = numPage => {
        setCurrentPage(numPage);
    };

    return (
        <div className={style.main}>
            <div className={style.container}>
                <h1>{t("s.h1")}</h1>
                <div className={style.section}>
                    <div className={style.code}>
                        <h3>{t("s.code")}</h3>
                        <FaSortAmountDown />
                    </div>
                    <div className={style.name}>
                        <h3>{t("s.name")}</h3>
                        <FaSortAmountDown />
                    </div>
                    <div className={style.number}>
                        <h3>{t("s.number")}</h3>
                        <FaSortAmountDown />
                    </div>
                </div>
                <div className={style.items}>
                    {
                        props.information[0]?.map((info, index) => {
                            if (((currentPage - 1) * 10 <= index) && ((currentPage * 10) > index)) {
                                return (
                                    <Link to={`/list/last/${info.id}`} className={style.item} key={index} onClick={() => props.fetchDocsDetailed_count_next_last(info.id, info)}>
                                        <div className={style.code}>
                                            <h3>{info.code}</h3>
                                        </div>
                                        <div className={style.name}>
                                            <Link to={`/list/last/${info.id}`}>{
                                                i18n.language === 'ru' ?
                                                    info.name_ru
                                                    : info.name_uz
                                            }</Link>
                                        </div>
                                        <div className={style.number}>
                                            <h3>{info.total_slip_counts}</h3>
                                        </div>
                                    </Link>
                                )
                            } else {
                                return null;
                            }
                        })
                    }
                </div>
                {
                    props.information[0]?.length > 10 && <div className="container" style={{ textAlign: 'center' }}>
                        <div className="box-theme">
                            <div className="demo">
                                <Pagination
                                    currentPage={currentPage}
                                    totalSize={Math.ceil((props.information[0]?.length / 10)) * 10}
                                    changeCurrentPage={changeCurrentPage}
                                    theme="bootstrap"
                                />

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

const mapStatToProps = state => {
    return {
        information: state.detailed_count_next
    }
}

export default connect(mapStatToProps, { fetchDocsDetailed_count_next_last })(StandartNext1);