import React from 'react'
import style from './Change.module.css'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'


function Guide(props) {
    const { t } = useTranslation();
    return (
        <div className={style.main}>
            <h1>{t('guide.h1')}</h1>
            <p style={{ width: '90%', margin: 'auto' }}>{props.selectedDoc.content?.split('').filter(letter => letter !== '.').join('')}</p>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        detail: state.detailed_count_next_last,
        selectedDoc: state.selectedDoc
    }
}


export default connect(mapStateToProps)(Guide)
