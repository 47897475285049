import React from 'react'
import style from './Main3.module.css'
import { BiTime } from 'react-icons/bi'
import img from '../../../assets/images/file.svg'
import { useTranslation } from 'react-i18next'
import Slider from "react-slick";
import { fetchFinalInfo } from '../../../actions'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux'
import Loader from '../../Loading/Loader'
import { Link } from 'react-router-dom'

function Main3(props) {
    const { t, i18n } = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        // beforeChange: function (currentSlide, nextSlide) {
        //     console.log("before change", currentSlide, nextSlide);
        // },
        // afterChange: function (currentSlide) {
        //     console.log("after change", currentSlide);
        // }
    };
    return (
        <div className={style.main}>
            <div className={style.container}>

                <h1>{t("main.lastDocsText")}</h1>
                <div className={style.sections}>
                    <div className={style.left}>
                        <Slider {...settings}>
                            {
                                props.carousel?.results ?
                                    props.carousel.results.map((doc, index) => {
                                        return (
                                            <Link onClick={() => props.fetchFinalInfo(doc)} to='/info' key={index} className={style.item}>
                                                <div className={style.top}>
                                                    <h2>{doc.doc_index} {doc.doc_registry_number}:{doc.approved_year}</h2>
                                                    <div className={style.time}>
                                                        <BiTime />
                                                        <p>Опубликовано</p>
                                                        <h5>01.05.2019</h5>
                                                    </div>
                                                </div>
                                                <p>{i18n.language === 'ru' ? doc.name_ru : doc.name_uz}</p>
                                                <h4>
                                                    {
                                                        i18n.language === 'ru' ?
                                                            doc.short_description.split(' ').slice(0, 15).join(' ') :
                                                            doc.short_description_uz.split(' ').slice(0, 15).join(' ')
                                                    }...
                                                </h4>
                                            </Link>
                                        )
                                    })
                                    :
                                    <Loader />
                            }
                        </Slider>
                    </div>
                    <div className={style.right}>
                        <div className={style.svg} style={{ backgroundImage: `url(${img})` }}></div>
                        <h1>{t("main.docName")}</h1>
                        <button>
                            {t("main.button3")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        carousel: state.carousel
    }
}

export default connect(mapStateToProps, { fetchFinalInfo })(Main3)
