import axios from 'axios'

export default axios.create({
    baseURL: 'https://sttadmin.unicon.uz/api/',
})



// https://sttadmin.unicon.uz/api/dictionary/code_oks/301/detailed_count/
// https://sttadmin.unicon.uz/api/dictionary/code_oks/323/detailed_count/

// https://sttadmin.unicon.uz/api/slip/1997/
// https://sttadmin.unicon.uz/api/slip/?published=true&ordering=-created_at