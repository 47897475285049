import React from 'react';
import style from '../../Standarts/StandartsParts/Standarts1.module.css'
import img from '../../../assets/images/inbox.svg'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

function StandartNext1(props) {
    const { i18n } = useTranslation();
    return (
        <div className={style.main}>
            <div className={style.container}>
                <img src={img} alt="logo" />
                <h1><span>{props.information[1]?.code}</span> - {
                    i18n.language === 'ru' ?
                        props.information[1]?.name_ru
                        : props.information[1]?.name_uz
                }</h1>
            </div>
        </div>
    )
}

const mapStatToProps = state => {
    return {
        information: state.detailed_count_next
    }
}

export default connect(mapStatToProps, null)(StandartNext1);
