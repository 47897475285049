import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import './I18Next'
import Loading from "./components/Loading/Loader";

import reducers from './reducer'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>
    </Provider>,
    document.querySelector('#root')
)