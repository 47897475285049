import api from '../API'


export const fetchAllDocs = () => async dispatch => {
    const response = await api.get('/dictionary/code_oks/counts/')
    dispatch({
        type: 'FETCH_ALL_DOCS',
        payload: response.data
    })
}
export const fetchCarousel = () => async dispatch => {
    const response = await api.get('/slip/?published=true&ordering=-created_at')
    dispatch({
        type: 'FETCH_CAROUSEL',
        payload: response.data
    })
}

export const fetchDocsDetailed_count = (id, documentType) => async dispatch => {
    console.log(window.location.href)
    const response = await api.get(`/dictionary/code_oks/${id}/detailed_count/`)
    dispatch({
        type: 'FETCH_DOCS_DETAILED_COUNT',
        payload: [response.data, documentType]
    })
}

export const fetchDocsDetailed_count_next = (id, documentType) => async dispatch => {
    const response = await api.get(`/dictionary/code_oks/${id}/detailed_count/`)
    dispatch({
        type: 'FETCH_DOCS_DETAILED_COUNT_NEXT',
        payload: [response.data, documentType]
    })
}

export const fetchDocsDetailed_count_next_last = (id, documentType) => async dispatch => {
    const response = await api.get(`/slip/?published=true&code_oks=${id}&page=1`)
    dispatch({
        type: 'FETCH_DOCS_DETAILED_COUNT_NEXT_LAST',
        payload: [response.data, documentType]
    })
}

// https://sttadmin.unicon.uz/api/slip/?published=true&doc_category=19&page=1

export const fetchFinalInfo = document => {
    return {
        type: 'FETCH_FINAL_INFO',
        payload: document
    }

}

export const changeLanguage = language => {
    return {
        type: 'CHANGE_LANGUAGE',
        payload: language
    }
}