import React from 'react'
import style from './Footer.module.css'
import img from '../../assets/images/foot.png'
import { MdLocationOn } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'


function Footer() {
    const { t } = useTranslation();
    return (
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.left}>
                    <img src={img} alt="logo" />
                    <h3>{t("footer.year")}</h3>
                    <h4>{t("footer.copyright")}</h4>
                </div>
                <div className={style.middle}>
                    <h3>{t("footer.address")}</h3>
                    <div className={style.address}>
                        <MdLocationOn />
                        <div className={style.nums}>
                            <h4>+998 71 235 9327</h4>
                            <h4>+998 71 237 6504</h4>
                        </div>
                    </div>
                </div>
                <div className={style.right}>
                    <h3>{t("footer.phone")}</h3>
                    <div className={style.address}>
                        <FaPhoneAlt />
                        <div className={style.nums}>
                            <h4>+998 71 235 9327</h4>
                            <h4>+998 71 237 6504</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
