import React, { useState } from 'react';
import style from './Navbar.module.css';
import logo from '../../assets/images/logo.svg'
import { FaPhoneAlt, FaCaretDown } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { fetchDocsDetailed_count_next_last } from '../../actions'

function Navbar(props) {
    const [language, setLanguage] = useState('O`zbek')

    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }
    // {t("footer.year")}
    return (
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.left}>
                    <div className={style.logoContainer}>
                        <div className={style.logo} style={{ backgroundImage: `url(${logo})` }}></div>
                        <h3>{t("navbar.logo")} </h3>
                    </div>
                    <div className={style.links}>
                        <NavLink to='/' activeStyle={{ borderBottom: ' 2px solid #DA1F28' }}>{t("navbar.link1")}</NavLink>
                        <h4>
                            {t("navbar.link2")}

                            <div className={style.invisible}>
                                <NavLink to='/'>{t("navbar.sublink1")}</NavLink>
                                <NavLink to='/'>{t("navbar.sublink2")}</NavLink>
                                <NavLink to='/'>{t("navbar.sublink3")}</NavLink>
                                <NavLink to='/'>{t("navbar.sublink4")}</NavLink>
                                <NavLink to='/'>{t("navbar.sublink5")}</NavLink>
                            </div>
                        </h4>
                    </div>
                </div>
                <div className={style.right}>
                    <div className={style.numbers}>
                        <div className={style.svgContainer}>
                            <FaPhoneAlt />
                        </div>
                        <div className={style.phones}>
                            <h5>+998 71 235 9327</h5>
                            <h5>+998 71 237 6504</h5>
                        </div>
                    </div>
                    <div className={style.langSearch}>
                        <div className={style.language}>
                            <h3>{language}</h3>
                            <div className={style.down}>
                                <FaCaretDown />
                            </div>
                            <div className={style.langs}>
                                {language !== 'O`zbek' && <h3 onClick={() => {
                                    setLanguage('O`zbek')
                                    changeLanguage('uz')
                                }}>O`zbek</h3>}
                                {language !== 'Русский' && <h3 onClick={() => {
                                    setLanguage('Русский')
                                    changeLanguage('ru')
                                }}>Русский</h3>}
                            </div>
                        </div>
                        <div className={style.search}>
                            <BsSearch />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { fetchDocsDetailed_count_next_last })(Navbar)
