import React, { useEffect } from 'react'
import style from './Main4.module.css'
import { FaSortAmountDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { fetchAllDocs, fetchDocsDetailed_count } from '../../../actions'
import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'

function Main4(props) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        props.fetchAllDocs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={style.main}>
            <div className={style.container}>
                <h1>{t("main.tableHead")}</h1>
                <div className={style.section}>
                    <div className={style.code}>
                        <h3>{t("main.code")}</h3>
                        <FaSortAmountDown />
                    </div>
                    <div className={style.name}>
                        <h3>{t("main.name")}</h3>
                        <FaSortAmountDown />
                    </div>
                    <div className={style.number}>
                        <h3>{t("main.number")}</h3>
                        <FaSortAmountDown />
                    </div>
                </div>
                <div className={style.items}>
                    {i18n.language === "ru" ?
                        props.allDocs.map((doc, index) => {
                            if (index < 11 && index !== 7) {
                                return (
                                    <Link to={`/list/${doc.id}`} className={style.item} key={index} onClick={() => props.fetchDocsDetailed_count(doc.id, doc)}>
                                        <div className={style.code}>
                                            <h3>{doc.code}</h3>
                                        </div>
                                        <div className={style.name}>
                                            <h3 >{doc.name_ru}</h3>
                                        </div>
                                        <div className={style.number}>
                                            <h3>{doc.total_slip_counts}</h3>
                                        </div>
                                    </Link>
                                )
                            } else {
                                return null
                            }
                        })
                        :
                        props.allDocs.map((doc, index) => {
                            if (index < 11 && index !== 7) {
                                return (
                                    <Link to={`/list/${doc.id}`} className={style.item} key={index} onClick={() => props.fetchDocsDetailed_count(doc.id, doc)}>
                                        <div className={style.code}>
                                            <h3>{doc.code}</h3>
                                        </div>
                                        <div className={style.name}>
                                            <h3 >{doc.name_uz}</h3>
                                        </div>
                                        <div className={style.number}>
                                            <h3>{doc.total_slip_counts}</h3>
                                        </div>
                                    </Link>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        allDocs: state.allDocs
    }
}

export default connect(mapStateToProps, { fetchAllDocs, fetchDocsDetailed_count })(Main4)
