import React, { Fragment } from 'react'
import Main2 from '../Main/MainParts/Main2'
import StandartNext1 from './StandartNextParts/StandartNext1'
import StandartNext2 from './StandartNextParts/StandartNext2'

function StandartNext() {
    return (
        <Fragment>
            <Main2 />
            <StandartNext1 />
            <StandartNext2 perPage={10} />
        </Fragment>
    )
}

export default StandartNext
